<template>
	<div class="addList">
		<frame :top1="top1" :top2="top2">
		<div class="titles1" style="margin-top: 10px;margin-right: 62%;">店铺>店铺列表</div>
			<list :titles='titles' :flag='flag' :contentC='datas' @del='refresh()'>
				
				    <div class="title" v-if="admin_type==1">
				    <div class="selname">商家类型:</div>
				    <Select v-model="value" placeholder="请选择" class="sel">
				    						<!-- <Option   value="">全部</Option> -->
				    <Option   v-for="item in options"
				    :key="item.value"
				    :label="item.label"
				    :value="item.value" @click.native="shopOpt(item.value)"></Option>
				     </Select>
				    </div>	
				<!-- <div>111</div> -->
			</list>
			
		</frame>
		<!-- <div class="block"> -->
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="currentPage4"
		    :page-sizes="[10,20]"
		    :page-size="pageSize"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="titlePage"
		  >
		  </el-pagination>
		  <!-- <div> -->
	</div>

</template>

<script>
	// import shopsearch from '../template/search.vue'
	import frame from '../public/Frame.vue';
	import list from '../template/List.vue';
	export default {
		name: 'addList',
		data() {
			var admin_type=this.$storage.getLocal('type')
			if(admin_type=='1')
			{
				var title = ['商家编号','类型','商家名称','联系人','品牌名称','行业分类','主营类目','入住时间','到期时间','操作'];
			}
			else
			{
				var title = ['商家编号','商家名称','联系人','品牌名称','行业分类','所属支行','主营类目','入住时间','到期时间','操作'];
			}
			console.log(admin_type)
			
			return {
				top1: '2-1',
				top2: ['2'],
				titles:title,
				admin_type:'',
				currentPage4: 1,
				titlePage:1,
				currentpage: 1,
				pageSize:10,
				flag:7,
				datas:null,
				options: [{
				          value: '1',
				          label: '商户'
				        },
						{
				          value: '2',
				          label: '店铺'
				        }],
				value:'',
			}
		},
		created() {
			var admin_type=this.$storage.getLocal('type')
			this.admin_type=admin_type
			console.log('888',this.admin_type)
			console.log('666',this.value)
			let token = this.$storage.getLocal('token');
			let limit = 10;
			let page = 1;
			let s_name = '';
			this.$request.storeList({token,page,limit,s_name,value:this.value}).then(res=>{
				if(res.code==0){
					this.datas = res.data;
					console.log(this.datas)
					this.titlePage=res.count
					console.log(res.data.length)
				}
			})
		},
		components: {
			frame,
			list
		},
		methods: {
			handleSizeChange(val) {
			  console.log(`每页 ${val} 条`);
			  
			   this.pageSize = val;
			  var admin_type=this.$storage.getLocal('type')
			  this.admin_type=admin_type
			  console.log('888',this.admin_type)
			  console.log('666',this.value)
			  let token = this.$storage.getLocal('token');
			  let limit = this.pageSize;
			  let page = 1;
			  let s_name = '';
			  this.$request.storeList({token,page,limit,s_name,value:this.value}).then(res=>{
			  	if(res.code==0){
			  		this.datas = res.data;
			  	}
			  })
			  
			  console.log("361", this.pageSize);
			},
			
			handleCurrentChange(val) {
			   var admin_type=this.$storage.getLocal('type')
			   this.admin_type=admin_type
			   console.log('888',this.admin_type)
			   console.log('666',this.value)
			   let token = this.$storage.getLocal('token');
			   let limit = this.pageSize;
			   let page = val;
			   let s_name = '';
			   this.$request.storeList({token,page,limit,s_name,value:this.value}).then(res=>{
			   	if(res.code==0){
			   		this.datas = res.data;
			   		console.log(this.datas)
			   		// this.titlePage=res.data.length
			   		console.log(res.data.length)
			   	}
			   })
			    },
			shopOpt(value){
				console.log(value)
				console.log('333',this.value)
				let token = this.$storage.getLocal('token');
				let limit = 10;
				let page = 1;
				let s_name = '';
				this.$request.storeList({token,page,limit,s_name,value}).then(res=>{
					if(res.code==0){
						this.datas = res.data;
						console.log(this.datas)
					}
				})
				},
				
				
			refresh(){
				console.log('222',this.value)
				let token = this.$storage.getLocal('token');
				let limit = 10;
				let page = 1;
				let s_name = '';
				this.$request.storeList({token,page,limit,s_name,value:this.value}).then(res=>{
					if(res.code==0){
						this.datas = res.data;
						console.log(this.datas)
					}
				})
			}
		}
	}
</script>


<style scoped="scoped">
.title
{
	display: flex;
}
.addList
{
background-color: rgb(245,247,249);
height: 800px;
}
.selname{
	width: 120px;
	margin-top: 5px;
	}
</style>
